@keyframes slide-up {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

.animated-header-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 2em;
  font-weight: bold;
  flex-wrap: nowrap;
  overflow-y: clip;
}

.animated-header-up {
  transform: translateY(0%);
  animation: slide-up var(--animation-duration) ease-in-out 1;
}

.animated-header-down {
  transform: translateY(0%);
  animation: slide-down var(--animation-duration) ease-in-out 1;
}

@media screen and (max-width: 500px) {
  .animated-header-container {
    font-size: 1em;
    width: 60%;
  }
}
@media screen and (min-width: 501px) and (max-width: 900px) {
  .animated-header-container {
    font-size: 1.5em;
    width: 80%;
  }
}
