@import url("https://fonts.googleapis.com/css2?family=Aboreto&family=Bungee&family=Montserrat&family=Playfair:opsz,wght@5..1200,300&family=Trirong:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aboreto&family=Bungee&family=Kalnia&family=Montserrat&family=Playfair:opsz,wght@5..1200,300&family=Trirong:wght@300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@property --distance {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 0%;
}
.homepage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: #dcdcdc;
  overflow: hidden;
  z-index: 1;
}

.title-card {
  height: 20vh;
  width: 100vw;
  background-color: #001f3f;
  transition: all 1s ease-in;
  transform-origin: right;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.4em;
}

.company-name {
  opacity: 100;
  margin: 0;
  background-image: -webkit-linear-gradient(
    45deg,
    white,
    #7f9fcf var(--distance),
    white
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: --distance 3s ease-in-out;
}

.company-name-container {
  height: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 35%;
  text-wrap: wrap;
}

.company-name-container img {
  height: 2em;
}

.veteran-owned {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 60%;
}
.company-name-container h3 {
  margin: 0;
  font-style: italic;
}
.navbar {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.navbar h3 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  text-align: center;
  opacity: 100;
}

.navbar h3:hover {
  background-color: #4c607e !important;
}
.links {
  height: 80vh;
  width: 100vw;
  background-color: #aaaaaa;
  transition: all 1s ease-in-out;
  transform-origin: right;
  box-shadow: 0px 10px 10px #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.link-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60vw;
  height: inherit;
  color: #333333;
  position: relative;
  background-color: #d3d3d3;
  overflow: hidden;
}

.link-container::before {
  content: "";
  position: absolute;
  top: 0;
  right: var(--before-right, 100%);
  width: 100%;
  height: 100%;
  background-image: var(--before-background-image, none);
  background-size: 100% 100%;
  transition: right 1s ease-in-out;
  z-index: 0;
}

.link-container h2 {
  font-family: "Kalnia", sans-serif;
  border: 3px solid white;
  padding: 20px 30px;
  border-radius: 20px;
  opacity: 100;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background-color: #d3d3d3;
  display: inline-block;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.link-container h2::before {
  content: "";
  position: absolute;
  top: 0;
  right: var(--before-right, 100%);
  width: 100%;
  height: 100%;
  background-color: #aaf0d1;
  transition: right 0.3s ease-in-out;
  z-index: -1;
}
.link-container h2:hover::before {
  right: var(--before-right, 0);
}

.link-container h2:hover {
  border: 3px solid #aaf0d1;
}

@keyframes square-in-hesitate {
  0% {
    clip-path: inset(100% 100% 100% 100%);
  }
  40% {
    clip-path: inset(33% 33% 33% 33%);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}

@media only screen and (max-width: 500px) {
  .company-name-container {
    width: 100%;
    height: 80%;
    font-size: 0.6em;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .veteran-owned {
    width: 3em;
  }
  .title-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title-card h1 {
    margin: 12px;
  }
  .navbar h3 {
    font-size: 0.8em;
    padding: 10px 8px 10px 8px;
    margin: 0px 3px;
    height: 100%;
    text-align: center;
  }

  .navbar {
    justify-content: space-around;
  }
  .link-container {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
  }

  .title-card {
    font-size: 1em;
    text-align: center;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1300px) {
  .company-name-container {
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .veteran-owned {
    width: 3em;
  }
  .title-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .navbar h3 {
    font-size: 1.5em;
    padding: 10px 8px 10px 8px;
    margin: 0px 3px;
    height: 100%;
    text-align: center;
  }

  .navbar {
    width: 70%;
    justify-content: space-around;
  }
  .link-container {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
  }

  .title-card {
    font-size: 1em;
    text-align: center;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1600px) {
  .company-name {
    font-size: 1.8em;
  }
}
