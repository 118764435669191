.background-video {
  position: fixed;
  top: 20vh;
  left: 0;
  height: auto;
  width: 100%;
  z-index: -1;
  opacity: 0.5;
}

.homeindex-container {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  width: 100%;
  height: 100%;
  transition: all 1s ease-in-out;
  background-size: cover;
  background-position: center center;
}

.homeindex-header {
  color: white;
  font-size: 2em;
  font-family: "GT Walsheim Pro";
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 70%;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.homeindex-header h1 {
  transition: all 0.3s ease-in;
}
.button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: white;
  border: none;
  font-family: "GT Walsheim Pro";
  padding: 10px 20px;
  margin: 0px 60px;
  font-size: 2em;
  text-wrap: nowrap;
  background-color: #001f3f;
  cursor: pointer;
  transition: all 0.2s ease-in;
  border: 2px solid white;
}

.hover-underline-animation:hover {
  color: #4c607e;
  background-color: white;
  border: 2px solid #4c607e;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 5px;
  bottom: 0;
  left: 0;
  background-color: #4c607e;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.mobile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 2em;
  color: white;
}
@media only screen and (max-width: 600px) {
  .homeindex-header {
    font-size: 1.3em;
    margin: auto;
    width: 80%;
    height: 70%;
  }

  .button-container {
    flex-direction: row;
  }

  .hover-underline-animation {
    margin: 30px 10px;
    text-wrap: nowrap;
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 1100px) {
  .homeindex-header {
    font-size: 1em;
    margin: auto;
    width: 70%;
    height: 70%;
  }

  .button-container {
    flex-direction: row;
  }

  .button-89 {
    margin: 30px 10px;
    text-wrap: nowrap;
    font-size: 2em;
  }
}
