@font-face {
  font-family: GT Walsheim Pro;
  src: local("GT Walsheim Pro Regular"), local("GTWalsheimProRegular"),
    url(../static/GTWalsheimProRegular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

.admin-screen-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: lightgrey;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.admin-navbar {
  display: flex;
  width: 100%;
  height: 10%;
  background-color: seagreen;
  justify-content: center;
  align-items: center;
}

.admin-navbar-tab {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "GT Walsheim Pro";
  font-size: 2em;
  transition: all 0.2s ease-in;
  cursor: pointer;
  text-align: center;
}

.admin-navbar-tab:hover {
  background-color: rgb(22, 65, 40);
}
.login-container {
  width: 100vw;
  height: 100vh;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login {
  width: 30%;
  height: 40%;
  box-shadow: 0px 0px 5px black;
  background-color: beige;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  padding: 20px;
}

.admin-input-label {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.admin-input-label input {
  width: 95%;
  padding: 5px;
  margin: 5px 0px;
  border: 1px solid black;
  border-radius: 5px;
  font-family: "GT Walsheim Pro";
}

.login-button {
  font-family: "GT Walsheim Pro";
  margin: auto;
  cursor: pointer;
  border: 2px solid black;
  padding: 5px 20px;
  border-radius: 15px;
  transition: all 0.2s ease-in;
}

.login-button:hover {
  background-color: black;
  color: white;
}

.close-create-job:hover {
  color: white;
}

.page-component-container {
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow: hidden;
}
@media only screen and (max-width: 1100px) {
  .login {
    width: 80%;
    height: 60%;
  }

  .admin-navbar-tab {
    width: 30%;
  }
  .admin-input-label input {
    width: 80%;
  }

  .login-button {
    margin: 10px auto;
  }
}
