.admin-job-create-container {
  width: 100vw;
  height: 100vh;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.admin-create-form {
  width: 60%;
  height: 90%;
  box-shadow: 0px 0px 5px black;
  background-color: beige;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.admin-submit-button {
  font-size: 1.7em;
  border: 2px solid black;
  margin: 5px;
  padding: 5px 15px;
  border-radius: 10px;
  background-color: seagreen;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.admin-submit-button:hover {
  background-color: white;
  color: seagreen;
  border: 2px solid seagreen;
}

.form-group {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
}

.form-group label {
  font-family: "GT Walsheim Pro";
  font-size: 1.5em;
}

.form-group input[type="text"] {
  width: 100%;
  height: 30px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}

.form-group select {
  width: 100%;
  height: 30px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}
.form-group-radio {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
}

.form-group-radio label {
  font-family: "GT Walsheim Pro";
  font-size: 1.5em;
}

.form-group input[type="number"] {
  width: 100%;
  height: 30px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}

.form-group textarea {
  width: 100%;
  height: 100px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  resize: none;
}
