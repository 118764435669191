.jobcard {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: start;
  align-items: start;
  border-bottom: 1px solid black;
  margin: 5px 0px;
}

.jobcard-title {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 60%;
}

.jobapply-salary {
  color: #001f3f;
  background-color: #87a9db;
  padding: 5px 10px;
  border-radius: 20px;
  width: 10%;
  text-align: center;
}

.job-salary {
  color: #001f3f;
  background-color: #8aabdd;
  padding: 5px 10px;
  border-radius: 20px;
  text-align: center;
}

.jobcard-title h3 {
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-in;
  margin: 0;
}

.jobcard-title h4 {
  margin: 10px 0px;
}

.jobcard-title h3:hover {
  color: #4c607e;
}
.jobcard-title > * {
  margin: 5px 10px;
}

.jobsearch-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 100%;
  font-family: "GT Walsheim Pro";
}

.jobfilter-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 80%;
  height: 20%;
}

.jobfilter-navbar {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 50%;
  width: 80%;
}

.jobfilter-container input[type="text"] {
  font-size: 2em;
  border: none;
  padding: 1px 0px;
}

.jobfilter-navbar h2 {
  padding: 5px 20px;
  text-wrap: nowrap;
  border: 2px solid white;
  cursor: pointer;
  font-size: 1.1em;
  transition: all 0.2s ease-in;
  background-color: #001f3f;
  color: white;
}

.selected {
  padding: 5px 20px;
  text-wrap: nowrap;
  cursor: pointer;
  transition: all 0.2s ease-in;
  background-color: #4c607e !important;
  border: 2px solid #4c607e !important;
  color: white;
}

.jobfilter-navbar h2:hover {
  background-color: #4c607e;
  border: 2px solid #4c607e;
  color: white;
}

.jobfilter-searchbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 50%;
  align-items: center;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  padding: none;
  font-size: 0.8em;
}

.jobfilter-searchbar h2 {
  padding: 2px 10px 3px 4px;
  margin: 0px;
  border: none;
  background-color: #001f3f;
  color: white;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.jobfilter-searchbar h2:hover {
  color: white;
  background-color: #4c607e;
}

.job-container {
  height: 80%;
  width: 80%;
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.reset-search {
  background-color: white;
  padding: 5px 15px;
  border: 2px solid black;
  border-radius: 20px;
  margin-left: 10px;
  color: black;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.reset-search:hover {
  background-color: black;
  color: white;
}
@media only screen and (max-width: 499px) {
  .reset-search {
    font-size: 1em;
    padding: 5px;
    margin-left: 5px;
    content: "Reset";
  }
  .jobapply-salary {
    width: 30%;
  }
  .jobfilter-container {
    display: flex;
    flex-direction: column;
    height: 30%;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }

  .jobfilter-navbar {
    height: 55%;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .jobfilter-navbar h2 {
    font-size: 0.9em;
    padding: 5px 10px;
    margin: 0px;
  }
  .jobfilter-searchbar {
    height: 20%;
    width: 100%;
    margin-bottom: 10px;
  }

  .jobfilter-searchbar h2 {
    font-size: 1em;
  }
  .jobfilter-container input[type="text"] {
    font-size: 1.6em;
    width: 70%;
    height: auto;
  }
}
@media only screen and (min-width: 500px) and (max-width: 599px) {
  .jobfilter-container {
    display: flex;
    flex-direction: column;
    height: 30%;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .jobapply-salary {
    width: 30%;
  }
  .jobfilter-navbar {
    height: 55%;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .jobfilter-navbar h2 {
    margin: 0px;
  }
  .jobfilter-searchbar {
    height: 20%;
    width: 80%;
    margin-bottom: 10px;
  }

  .jobfilter-container input[type="text"] {
    font-size: 1.6em;
    width: 70%;
    height: auto;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1400px) {
  .jobfilter-container {
    display: flex;
    flex-direction: column;
    height: 20%;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }

  .jobapply-salary {
    width: 30%;
  }
  .jobfilter-navbar {
    height: 55%;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .jobfilter-navbar h2 {
    margin: 0px;
  }
  .jobfilter-searchbar {
    height: 20%;
    width: 80%;
    margin-bottom: 10px;
  }

  .jobfilter-container input[type="text"] {
    font-size: 2em;
    width: 100%;
    height: auto;
  }
}
