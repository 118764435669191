@font-face {
  font-family: GT Walsheim Pro;
  src: local("GT Walsheim Pro Regular"), local("GTWalsheimProRegular"),
    url(../static/GTWalsheimProRegular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

.contact-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  width: 100%;
  height: 100%;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: white;
  align-items: center;
  width: 90%;
  background-color: rgba(0, 31, 63, 1);
  height: 70%;
  padding: 20px;
  box-shadow: 0px 0px 10px black;
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 30%;
  height: 95%;
  font-family: "GT Walsheim Pro";
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 15%;
  color: white;
  font-size: 0.8em;
  padding: 15px;
  border-radius: 20%;
  background-color: rgba(0, 31, 63, 1);
  box-shadow: 0px 0px 10px black;
}

.contact-info > * {
  margin: 5px;
}
.contact-header {
  display: flex;
  flex-direction: column;
  color: white;
  height: 100%;
  width: 60%;
  margin: auto;
  justify-content: center;
  align-items: start;
  font-size: 1.5em;
}

.input-with-label {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-with-label h1 {
  font-size: 1.4em;
  margin: 10px;
  font-family: "GT Walsheim Pro";
}
.input-with-label input {
  width: 95%;
  font-size: 1.3em;
}

.input-with-label textarea {
  height: 125px;
  width: 100%;
  resize: none;
}
.contact-mobile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20%;
  width: 100%;
  color: white;
  font-size: 1.5em;
}
.submit-button {
  display: inline-block;
  position: relative;
  color: white;
  border: none;
  font-family: "GT Walsheim Pro";
  padding: 10px 20px;
  font-size: 2em;
  text-wrap: nowrap;
  background-color: #4c607e;
  cursor: pointer;
  transition: all 0.2s ease-in;
  border: 2px solid white;
}

.submit-button:hover {
  color: #4c607e;
  background-color: white;
  border: 2px solid #4c607e;
}

.submit-button::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 5px;
  bottom: 0;
  left: 0;
  background-color: #4c607e;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.submit-button:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.resume-button {
  display: inline-block;
  position: relative;
  color: white;
  border: none;
  font-family: "GT Walsheim Pro";
  padding: 10px 20px;
  font-size: 2em;
  text-wrap: nowrap;
  background-color: #4c607e;
  cursor: pointer;
  transition: all 0.2s ease-in;
  border: 2px solid white;
}

.resume-button:hover {
  color: #4c607e;
  background-color: white;
  border: 2px solid #4c607e;
}

.resume-button::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 5px;
  bottom: 0;
  left: 0;
  background-color: #4c607e;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.resume-button:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
@media only screen and (max-width: 699px) {
  .contact-container {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }

  .contact-header {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 80%;
    height: 15%;
    font-size: 0.6em;
    margin: 0px;
  }
  .contact-header h3 {
    font-size: 1.4em;
  }

  .contact-header h1 {
    margin: 5px;
  }

  .contact-form {
    height: 70%;
    background-color: rgba(0, 31, 63, 0.65);
  }

  .contact-info {
    background-color: rgba(0, 31, 63, 0.65);
  }
  .contact-form-container {
    width: 70%;
    height: 80%;
    font-size: 0.8em;
  }
  .contact-form textarea {
    height: 40px;
  }

  .submit-button {
    font-size: 1.3em;
  }
  .resume-button {
    font-size: 1.3em;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1300px) {
  .contact-container {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }

  .contact-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 80%;
    height: 20%;
    font-size: 1em;
  }

  .contact-form {
    width: 70%;
    height: 70%;
    font-size: 1em;
    background-color: rgba(0, 31, 63, 0.65);
  }

  .contact-info {
    background-color: rgba(0, 31, 63, 0.65);
  }
  .contact-form-container {
    width: 80%;
    height: 80%;
    font-size: 0.8em;
  }
  .contact-form textarea {
    height: 80px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1800px) {
  .contact-form textarea {
    height: 150px;
  }
}
