.about-container {
  font-family: "GT Walsheim Pro";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100%;
  width: 100%;
  background: #4c607e;
  background: linear-gradient(
    180deg,
    rgba(112, 112, 112, 0) 0%,
    rgba(112, 112, 112, 0) 45%,
    #4c607e 46%
  );
}

ul {
  margin: 0;
}
.about-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
  font-size: 2em;
  color: white;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.about-header > * {
  width: inherit;
}
.about-section {
  width: 70%;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px black;
  overflow-y: scroll;
  overflow-x: hidden;
}

.about-section h1,
.about-section h2 {
  color: #001f3f;
}

.about-buttons {
  display: flex;
  flex: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.about-buttons h1 {
  border: 2px solid #4c607e;
  color: #4c607e;
  padding: 5px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.about-buttons h1:hover {
  background-color: #4c607e;
  color: white;
}

.about-highlight {
  margin-right: 5px;
  font-size: 1.2em;
  font-weight: bold;
}
@media only screen and (max-width: 499px) {
  .about-header {
    font-size: 1.5em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .about-section {
    font-size: 0.8em;
    overflow-y: scroll;
    height: 67%;
    width: 83%;
  }

  .about-buttons {
    justify-content: space-between;
    width: 100%;
  }

  .about-buttons h1 {
    margin: 0px 5px;
    width: 45%;
    padding: 5px 15px;
    text-align: center;
  }
}
@media only screen and(min-width: 500px) and (max-width: 600px) {
  .about-header {
    font-size: 1.8em;
    width: 80%;
    text-align: center;
  }

  .about-section {
    font-size: 0.8em;
    overflow-y: scroll;
    height: 67%;
  }

  .about-buttons {
    justify-content: space-between;
  }

  .about-buttons h1 {
    margin: 0px 5px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1100px) {
  .about-section {
    height: 70%;
    overflow-y: scroll;
  }
}
